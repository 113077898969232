import React from 'react';
import { useAppSelector } from '../../app/store';
import selectors from '../../app/selectors';
import { Box, BoxProps, Button, Center, Flex, Icon, Text } from '@chakra-ui/react';
import navigationDefinitions, { NavigationDefinitionProperties } from '../../app/navigationDefinitions';
import { BsChatDots, BsChevronExpand, BsJoystick } from 'react-icons/bs';
import AppLink from '../app-link/appLink';
import actions from '../../app/actions';
import { useDispatch } from 'react-redux';
import OrganizationManagementMenu from './../organization-management-menu/organizationManagementMenu';
import { Plan } from '@um/uptime-monitoring-shared';
import { format } from 'date-fns';
import { useSelectedOrganizationQuery, useSelectedOrganizationSubscriptionsQuery } from '../../query/organizationQueries';
import { useRouteActive } from '../../hooks/useRouteActive';

type SidebarLinkProps = {
  text: string;
  href: string;
  icon?: any;
  active?: boolean | ((path: string) => boolean);
  navChildren?: NavigationDefinitionProperties[];
  onClick?: () => void;
};

const Sidebar = () => {
  const dispatch = useDispatch();
  const admin = useAppSelector(selectors.selectIsAdmin);
  const { isLoading: subscriptionsLoading, data: subscriptionsResponse } = useSelectedOrganizationSubscriptionsQuery();
  const { isLoading, data: organization } = useSelectedOrganizationQuery();
  const activeSubscription = subscriptionsResponse?.activeSubscription;
  const { initialized, initializationError } = useAppSelector((state) => state.app);

  const openChat = () => {
    if (window.$crisp) {
      window.$crisp.push(['do', 'chat:open']);
    }
  };

  const showUpgrade = (!activeSubscription && organization?.plan === Plan.FREE) || organization?.trialActive;
  const canStartTrial = !organization?.trialActive && !organization?.trialCompleted && organization?.plan === Plan.FREE;

  if (isLoading || subscriptionsLoading || initializationError || !initialized) {
    return null;
  }

  return (
    <Box
      position="sticky"
      zIndex={1}
      top="0"
      width="260px"
      height="100%"
      minH="100vh"
      background="transparent !important"
      borderRight="1px"
      borderStyle="solid"
      borderColor="gray.200"
    >
      <Flex justifyContent="space-between" flexDirection="column">
        <Flex flexDirection="column" gap={4} p={4} fontSize="lg">
          <OrganizationManagementMenu />
          {navigationDefinitions
            .filter((def) => !def.admin || (def.admin && admin))
            .map((def) => (
              <SidebarLink key={def.to} href={def.to} active={def.active} icon={def.icon} text={def.label} navChildren={def.children} />
            ))}
        </Flex>
        <Flex flexDirection="column" gap={4} p={4} fontSize="lg">
          {admin && (
            <SidebarLink href="" onClick={() => dispatch(actions.app.setShowAdmin(true))} active={false} icon={BsJoystick} text="Administration" />
          )}
          <Flex direction="column" gap={2} p={4} alignItems="center">
            <Flex fontSize="lg" gap={1}>
              {!organization?.trialActive && <Box>Plan:</Box>}
              {organization?.trialActive && <Box>Trial plan:</Box>}
              <Text as="span" fontWeight="bold" textTransform="capitalize">
                {organization?.plan?.toLowerCase()}
              </Text>


            </Flex>
            {organization?.plan?.toLowerCase() === 'free' && (
            <Flex color='red' textAlign='center'>
              Upgrade now to avail <br />1-minute check intervals
            </Flex>
            )}
            {organization?.trialActive && organization?.trialEndsAt && (
              <Box fontSize="sm">Trial ends on {format(new Date(organization.trialEndsAt), 'yyyy-MM-dd')}</Box>
            )}
            {showUpgrade && (
              <Button colorScheme="darkGreen" size="sm" onClick={() => dispatch(actions.upgrade.showUpgradeModal(null))}>
                Upgrade
              </Button>
            )}
            {/* {canStartTrial && (
              <Button colorScheme="gray" size="sm" onClick={() => dispatch(actions.upgrade.showUpgradeModal(null))}>
                Start trial
              </Button>
            )} */}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const SidebarLink: React.FC<SidebarLinkProps> = ({ href, icon, text, active, navChildren = [], onClick }) => {
  const handleOnClick = (evt) => {
    if (onClick) {
      evt.preventDefault();
      evt.stopPropagation();

      onClick();
    }
  };

  const hasChildren = navChildren && navChildren.length > 0;
  const isParentRouteActive = useRouteActive(href, active);

  return (
    <Box>
      <AppLink
        display="flex"
        gap="3"
        px="4"
        py="2"
        borderRadius="md"
        to={href}
        onClick={handleOnClick}
        alignItems="center"
        cursor="pointer"
        color="black"
        background={isParentRouteActive ? 'gray.200' : 'transparent'}
        fontWeight="bold"
        _hover={{ background: 'gray.300', color: 'black' }}
        width="100%"
        justifyContent="space-between"
      >
        <Flex gap={3}>
          {icon && <Icon w={6} h={6} fontWeight="bold" as={icon} />}
          <div>{text}</div>
        </Flex>
        {hasChildren && (
          <Flex alignItems="center">
            <Icon as={BsChevronExpand} />
          </Flex>
        )}
      </AppLink>

      {isParentRouteActive && hasChildren && (
        <Flex flexDirection="column" gap={1} mt={2}>
          {navChildren.map((item) => {
            const isActive = useRouteActive(item.to, item.active);

            return (
              <AppLink
                key={item.to}
                display="flex"
                gap="2"
                pl={14}
                py="1"
                borderRadius="md"
                to={item.to}
                onClick={handleOnClick}
                alignItems="center"
                cursor="pointer"
                color="black"
                background={isActive ? 'gray.200' : 'transparent'}
                fontWeight="bold"
                fontSize="sm"
                _hover={{ background: 'gray.300', color: 'black' }}
              >
                {item.icon && <Icon fontWeight="bold" as={item.icon} />}
                <div>{item.label}</div>
              </AppLink>
            );
          })}
        </Flex>
      )}
    </Box>
  );
};

const DesktopSidebar: React.FC<BoxProps> = ({ ...rest }) => {
  return (
    <Box {...rest}>
      <Box height="100%" display={{ base: 'none', md: 'block' }}>
        <Sidebar />
      </Box>
    </Box>
  );
};

export { DesktopSidebar };
