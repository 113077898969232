import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import { Box, Button, Flex, StackDivider, VStack } from '@chakra-ui/react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import toast from 'react-hot-toast';
import { logError } from '../../utils/logError';
import { Plan } from '@um/uptime-monitoring-shared';
import { useOrganizationSetupMutation, useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { ControlledInput } from '../input/input';
import formValidationRules from '../../utils/formValidationRules';
import { MutationAction } from '../../query/mutationAction';
import Card from '../card/card';
import WebsiteLink from '../website-link/websiteLink';
import PlanSelectionCard from '../plan-selection-card/planSelectionCard';
import PlanDescriptions from '@um/uptime-monitoring-shared/dist/model/planDescriptions';

export type SetupOrganizationFormModel = {
  name: string;
  plan: Plan;
};

type Props = {
  onSetupCompleted?: () => void;
};

const { required } = formValidationRules;

const CompleteOrganizationSetupForm: React.FC<Props> = ({ onSetupCompleted }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const formMethods = useForm<SetupOrganizationFormModel>({ defaultValues: { name: '', plan: Plan.TEAM } });
  const { handleSubmit, control, setValue, watch } = formMethods;
  const { mutateAsync, isLoading } = useOrganizationSetupMutation();

  const submit = async (data: SetupOrganizationFormModel) => {
    await mutateAsync(
      { organizationId, setupRequest: data, action: MutationAction.POST },
      {
        onSuccess: () => {
          toast.success('Welcome to StatusPing!');

          if (onSetupCompleted) {
            onSetupCompleted();
          }
        },
        onError: (error: any) => {
          toast.error('Error completing organization setup. Please try again later');
          logError(error);
        },
      }
    );
  };

  const onSelectPlan = (plan: Plan) => setValue('plan', plan);
  const { plan } = watch();

  return (
    <Card w="100%">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submit)}>
          <Flex flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
            <VStack divider={<StackDivider borderColor="gray.200" />} width="100%" spacing={4} align="stretch">
              <FormFieldWrapper title="Organization name">
                <ControlledInput name="name" isDisabled={isLoading} rules={{ required }} control={control} />
              </FormFieldWrapper>
              <Box>
                <FormFieldWrapper title="Choose your plan" />
                <Flex gap={6} mt={8} flexDirection={{ base: 'column', md: 'row' }}>
                  <PlanSelectionCard
                    name={
                      <Box>
                        <Box fontSize="xl" lineHeight="1">
                          {PlanDescriptions[Plan.FREE].name} plan
                        </Box>
                        <Box color="gray.600">Always free</Box>
                      </Box>
                    }
                    features={PlanDescriptions[Plan.FREE].features}
                    onSelected={() => onSelectPlan(Plan.FREE)}
                    active={plan === Plan.FREE}
                  />
                  <PlanSelectionCard
                    name={
                      <Box>
                        <Box fontSize="xl" lineHeight="1" fontWeight="bold">
                          {PlanDescriptions[Plan.TEAM].name} plan
                        </Box>
                        <Box color="gray.600">21 days trial</Box>
                      </Box>
                    }
                    features={PlanDescriptions[Plan.TEAM].features}
                    onSelected={() => onSelectPlan(Plan.TEAM)}
                    active={plan === Plan.TEAM}
                  />
                </Flex>
                <Flex alignItems="center" justifyContent="center" width="100%" mt={4}>
                  <WebsiteLink href="https://statusping.co/pricing#plan-comparison" target="_blank">
                    Full plan comparison
                  </WebsiteLink>
                </Flex>
              </Box>
            </VStack>
            <Button type="submit" isDisabled={isLoading} isLoading={isLoading} colorScheme={'blue'} size="lg" width="min-content" mt={8}>
              Complete organization setup
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </Card>
  );
};

export default CompleteOrganizationSetupForm;
