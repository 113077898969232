import {
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import React, { useEffect } from 'react';
import { useAppSelector } from '../../app/store';
import selectors from '../../app/selectors';
import { AiOutlineLogout } from 'react-icons/ai';
import statusPingClient from '../../app/client/statusPingClient';
import Routes from '../../utils/routes';
import AppLink from '../app-link/appLink';
import { BsChevronDown, BsJoystick, BsPerson } from 'react-icons/bs';
import navigationDefinitions from '../../app/navigationDefinitions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from '../../app/actions';
import OrganizationManagementMenu from '../organization-management-menu/organizationManagementMenu';
import { combineUsersName } from '../../utils/combineUsersName';

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const user = useAppSelector(selectors.selectUser);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      onToggle();
    }
  }, [location?.pathname]);

  const logout = () => {
    const handler = async () => {
      await statusPingClient.protectedGetLogout();

      window.location.href = Routes.LOGIN;
    };

    handler();
  };

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      color={useColorModeValue('gray.600', 'white')}
      borderBottom={1}
      borderStyle={'solid'}
      borderColor={useColorModeValue('gray.200', 'gray.900')}
    >
      <Flex justifyContent="center" alignItems="center" width="100%">
        <Flex py={{ base: 2 }} px={{ base: 4 }} align={'center'} justifyContent="space-between" maxW="container.xl" width="100%">
          <Flex ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex justify="start">
            <Text
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              fontFamily={'heading'}
              color={useColorModeValue('gray.800', 'white')}
            >
              <Link href="/">
                <Image maxHeight="64px" src="/logo.png" alt="StatusPing Logo" />
              </Link>
            </Text>
          </Flex>

          <Stack direction={'row'} spacing={6}>
            <Menu>
              {user && (
                <MenuButton as={Button} variant="ghost">
                  <Box display="flex" alignItems="center" justifyContent="center" cursor="pointer">
                    <Flex align="center" gap="8px">
                      <Icon as={BsChevronDown} />
                      <Avatar size="sm" name={combineUsersName(user)} />
                    </Flex>
                  </Box>
                </MenuButton>
              )}
              <MenuList fontSize="xl">
                <MenuGroup title={user?.email}>
                  <MenuItem icon={<Icon boxSize={6} as={BsPerson} />} onClick={() => navigate(Routes.USER)}>
                    Profile
                  </MenuItem>
                  <MenuItem icon={<Icon boxSize={6} as={AiOutlineLogout} />} onClick={logout}>
                    Logout
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const MobileNav = () => {
  const admin = useAppSelector(selectors.selectIsAdmin);
  const dispatch = useDispatch();

  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={2} display={{ md: 'none' }} divider={<StackDivider margin={0} borderColor="gray.200" />}>
      <OrganizationManagementMenu />
      {navigationDefinitions
        .filter((def) => !def.admin || (def.admin && admin))
        .map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      {admin && <MobileNavItem key="admin" icon={BsJoystick} label="Administration" onClick={() => dispatch(actions.app.setShowAdmin(true))} />}
    </Stack>
  );
};

const MobileNavItem = ({ label, icon = null, to = null, onClick = null }) => {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={AppLink}
        to={to || '#'}
        justify={'center'}
        align={'center'}
        gap="2"
        fontSize="lg"
        onClick={onClick}
        color="black"
        _hover={{
          textDecoration: 'none',
        }}
      >
        {icon && <Icon w={6} h={6} as={icon} />}
        <Text fontWeight={600} fontSize="xl">
          {label}
        </Text>
      </Flex>
    </Stack>
  );
};
