import React, { useState } from 'react';
import { operationWrapper } from '../utils/operationWrapper';
import Routes from '../utils/routes';
import statusPingClient from '../app/client/statusPingClient';
import RequestResetPasswordForm, { RequestResetPasswordFormData } from '../components/request-reset-password-form/requestResetPasswordForm';
import { toast } from 'react-hot-toast';
import { dismissibleToast } from '../utils/dismissibleToast';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const onRequestResetPassword = async (data: RequestResetPasswordFormData) => {
    const requestResetPassword = operationWrapper(
      async () => {
        setLoading(true);
        setDisabled(true);

        await statusPingClient.publicPostRequestResetPassword(data.email);
      },
      {
        successCallback: () => {
          setLoading(false);

          toast.success(dismissibleToast('We have sent instructions on how to reset your password to your email address'), {
            duration: 99999999,
          });

          navigate(Routes.LOGIN);
        },
        errorCallback: (e: any) => {
          setLoading(false);
          setDisabled(false);

          const errorMessage = e?.response?.data?.message;
          setError(errorMessage || 'Error occurred please try again later');
        },
      }
    );

    await requestResetPassword();
  };

  return (
    <Flex minH="100vh" alignItems="center" justifyContent="center" py={12} px={4} backgroundColor="gray.200">
      <Flex maxW="container.xs" width="100%" flexDirection="column" gap={6}>
        <Box as="img" mx={'auto'} w={'auto'} maxHeight="64px" src="/logo.png" alt="StatusPing" />
        <RequestResetPasswordForm onRequestResetPassword={onRequestResetPassword} loading={loading} disabled={disabled} error={error} />
      </Flex>
    </Flex>
  );
};

export default ForgotPasswordPage;
