import React, { useEffect, useMemo, useState } from 'react';
import { operationWrapper } from '../utils/operationWrapper';
import Routes from '../utils/routes';
import statusPingClient from '../app/client/statusPingClient';
import { toast } from 'react-hot-toast';
import ResetPasswordForm, { ResetPasswordFormData } from '../components/reset-password-form/resetPasswordForm';
import { dismissibleToast } from '../utils/dismissibleToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const token = useMemo(() => searchParams.get('token'), [searchParams.get('token')]);

  useEffect(() => {
    if (!token) {
      navigate(Routes.LOGIN);
    }
  }, [token]);

  const onResetPassword = async (data: ResetPasswordFormData) => {
    const requestResetPassword = operationWrapper(
      async () => {
        setLoading(true);
        setDisabled(true);

        await statusPingClient.publicPostResetPassword(token, data.password);
      },
      {
        successCallback: () => {
          setLoading(false);

          toast.success(dismissibleToast('Your password has been changed'), {
            duration: 99999999,
          });

          navigate(Routes.LOGIN);
        },
        errorCallback: (e: any) => {
          setLoading(false);
          setDisabled(false);

          const errorMessage = e?.response?.data?.message;
          setError(errorMessage || 'Error occurred please try again later');
        },
      }
    );

    await requestResetPassword();
  };

  if (!token) {
    return null;
  }

  return (
    <Flex minH="100vh" alignItems="center" justifyContent="center" py={12} px={4} backgroundColor="gray.200">
      <Flex maxW="container.xs" width="100%" flexDirection="column" gap={6}>
        <Box as="img" mx={'auto'} w={'auto'} maxHeight="64px" src="/logo.png" alt="StatusPing" />
        <ResetPasswordForm onResetPassword={onResetPassword} loading={loading} disabled={disabled} error={error} />
      </Flex>
    </Flex>
  );
};

export default ResetPasswordPage;
